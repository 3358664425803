<template>
  <Loading v-model:active="isLoading"
    :can-cancel="false"
    :is-full-page="true"
    loader="dots"
    color="#e73644"
    z-index="99999999"/>
  <router-view/>
</template>

<script>
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  export default {
    name: 'App',
    components: {
      Loading
    },
    data() {
      return {
        isLoading: false,
      }
    },
    methods: {
      
    },
    computed: {
      
    }
  }
</script>
<style>
  @import 'https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';
</style>