import ENDPOINT from "@/api/endpoints";
import { HTTP } from "@/api/http-common";

export function tokenLogin(payload) {
  return HTTP({
    url: ENDPOINT.TOKEN_LOGIN,
    method: "Post",
    data: payload
  })
}

export function sendDeleteRequest(encrypted) {
  return HTTP({
    url: ENDPOINT.USERS + '/' + encrypted + ENDPOINT.ACCOUNT_DELETE_REQUEST,
    method: "post",
  })
}

export function sendDeleteFeedback(encrypted, payload) {
  return HTTP({
    url: ENDPOINT.USERS + '/' + encrypted + ENDPOINT.ACCOUNT_DELETE_FEEDBACK,
    method: "post",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}