import axios from "axios";
import _isNil from "lodash/isNil";

function buildQueries(params, prefix = "") {
  let requestString = "";
  Object.keys(params).forEach(key => {
    if (!_isNil(params[key]) && params[key] !== "") {
      requestString += `${prefix}${key}=${params[key]}&`;
    }
  });
  return requestString;
}

const DOMAIN = process.env.VUE_APP_API_URL;//"http://beta-api.ovvyapp.com";

const API_VERSION = "/api";
const HTTP = axios.create({
  baseURL: `${DOMAIN}${API_VERSION}`,
  headers: {
    // Authorization: `Bearer ${getValueFromStorageByKey(
    //   LOCAL_STORAGE_KEY.ACCESS_TOKEN
    // )}`,
    "Access-Control-Allow-Origin": "*"
  }
});

export { HTTP, buildQueries };
