<template>
  <!-- <section class="header">
    <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 padding-none text-center">
            <div class="header-nav">
              <div class="title">
                <label class="text-white">Account Deletion</label>
               </div>
                 <div class="back">
                   <a href="https://ovvyapp.com/"><i class="fa fa-long-arrow-left text-white"></i></a>
                 </div>
               </div>
            </div>
          </div>
        </div>
  </section> -->
  <section v-if="showForm && !showNextForm" class="header-nav-margin">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <h1 class="my-4 text-center"><strong>IMPORTANT</strong></h1>
          <p>- Account deletion is not reversible. Once the deletion process is completed, you will no longer be able to access your account , past transactions and job posts.</p>
          <p>- Any unutilized points, credits will be forfeited.</p>
          <p>- Your account deletion request will only be executed if there is no unsettled job / bid / payment.</p>
          <p>- Any transaction information will be kept for accounting and audit purposes.</p>
          <p>- If you would like to engage the services on Ovvy again (which we certainly hope you do), you may re-register an account with us.</p>
          <button @click="deleteAcct" class="btn mt-5">Proceed to delete my Account</button>
        </div>
      </div>
    </div>
  </section>

  <section v-if="showForm && showNextForm" class="header-nav-margin">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 mt-5 text-center">
          <p>Your Account Deletion request has been received and currently under review. You will receive a confirmation via email within 7 business days.</p>
          <p>To help us improve, do share with us your feedback, suggestion, and reason for leaving us:</p>
          <textarea v-model="feedback"
            name="feedback" cols="30" rows="5" class="form-control mb-2" :class="hasError ? 'error' : ''" @keyup="hasError=false;errorMsg= ''"
            placeholder="Type feedback here"></textarea>
          <small v-if="hasError" class="text-danger">{{errorMsg}}</small>

          <button @click="submitFeedback" class="btn mt-5">Submit Feedback</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { tokenLogin, sendDeleteRequest, sendDeleteFeedback } from "@/api";
import { lsWatcher as ls } from "../main";

export default {
  name: 'HomeView',
  components: {
    // HelloWorld
  },
  data() {
    return {
      showForm: true,
      showNextForm: false,
      feedback: '',
      encrypted: '',
      hasError: false,
      errorMsg: ''
    }
  },
  methods: {
    async deleteAcct() {
      const $this = this
      $this.$root.isLoading = true
      await sendDeleteRequest($this.encrypted).then(response => {
        console.log(response)
        $this.$root.isLoading = false
        $this.showNextForm = true
        ls.setItem("acctDelReqEmail", 1, 86400000 * 15) // 15 days
        ls.setItem("acctDelReqToken", $this.$route.params.token, 86400000 * 15) // 15 days
      }).catch(error => {
          $this.$root.isLoading = false
          console.log(error);
          let msg = ''
          if(error.response.data.message) {
            msg = error.response.data.message
          }else {
            msg = 'An error occured. Please try again.'
          }
          $this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: msg,
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.href = 'https://ovvyapp.com/';
          });
      })
    },
    async submitFeedback() {
      const $this = this

      this.hasError = false;
      this.errorMsg = '';
      if(this.feedback.trim() == '') {
        this.hasError = true;
        this.errorMsg = 'Feedback must not be empty.';
      }

      if(!this.hasError) {
        $this.$root.isLoading = true
        let formData = new FormData()
        formData.append('feedback', this.feedback)
        await sendDeleteFeedback($this.encrypted, formData).then(response => {
          console.log(response)
          $this.$root.isLoading = false
          $this.$swal({
              icon: 'success',
              title: 'Feedback Sent',
              confirmButtonText: 'OK',
              confirmButtonColor: '#e73644',
            }).then(() => {
              $this.$root.isLoading = true
              window.location.href = 'https://ovvyapp.com/';
            });
          // $this.showNextForm = true
        }).catch(error => {
            $this.$root.isLoading = false
            console.log(error);
            let msg = ''
            if(error.response.data.message) {
              msg = error.response.data.message
            }else {
              msg = 'An error occured. Please try again.'
            }
            $this.$swal({
              icon: 'error',
              title: 'Oops...',
              text: msg,
              confirmButtonText: 'OK',
            }).then(() => {
              window.location.href = 'https://ovvyapp.com/';
            });
        })
      }
    },
    async getUserByToken() {
      const $this = this
      let token = this.$route.params.token;
      $this.$root.isLoading = true
      await tokenLogin({
        token: token,
        include: 'active_delete_request'
      }).then(response => {
        let data = response.data.data;
        console.log(data)
        $this.encrypted = data.encrypted;
        
        $this.$root.isLoading = false
        
        if(data.active_delete_request.length > 0) {
          $this.$swal({
            icon: 'warning',
            text: 'You already sent your account deletion request. Do you want to send a feedback instead?',
            confirmButtonText: 'Yes',
            confirmButtonColor: '#e73644',
            showCancelButton: true,
            cancelButtonText: 'No'
          }).then((response) => {
            // console.log(response)
            if(response.isDismissed) {
              $this.$root.isLoading = true
              window.location.href = 'https://ovvyapp.com/';
            }else {
              $this.showNextForm = true
            }
          });
        }
        

      }).catch(error => {
          $this.$root.isLoading = false
          console.log(error);
          let msg = ''
          if(error.response.data.message) {
            msg = error.response.data.message
            if(msg == 'Token is invalid') msg = 'User does not exist.'
          }else {
            msg = 'An error occured. Please try again.'
          }
          $this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: msg,
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.href = 'https://ovvyapp.com/';
          });
      })
    },
  },
  created() {
    this.$root.isLoading = true
    const $this = this
    if(!this.$route.params.token) {
      window.location.href = 'https://ovvyapp.com/';
    }else {
      $this.getUserByToken()
      // if(ls.getItem("acctDelReqEmail").value !== null) {
      //   if(ls.getItem("acctDelReqToken").value == $this.$route.params.token) {
      //     $this.$swal({
      //       icon: 'warning',
      //       text: 'You already sent your account deletion request. Do you want to send another one?',
      //       confirmButtonText: 'Yes',
      //       confirmButtonColor: '#e73644',
      //       showCancelButton: true,
      //       cancelButtonText: 'No'
      //     }).then((response) => {
      //       // console.log(response)
      //       if(response.isDismissed) {
      //         $this.$root.isLoading = true
      //         window.location.href = 'https://ovvyapp.com/';
      //       }
      //     });
      //   }
      // }
    }
  }

}
</script>
